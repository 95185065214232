import { Box, CardContent, CardMedia, Container, Typography } from '@mui/material';

function App() {
  return (
    // center vertically container
    <Container maxWidth='sm' sx={{ alignItems: 'center', display: 'flex', minHeight: '100vh' }}>
      <Box>
        <CardMedia
          component="img"
          image="img/logo.svg"
          sx={{ maxWidth: 320, margin: 'auto' }}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Pagină în construcție
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <Typography>În curând vom reveni cu informații noi.</Typography>
            <Typography>Între timp, ne puteți contacta pe email la: <a href="mailto:contact@gdcode.ro">contact@gdcode.ro</a></Typography>
          </Typography>
        </CardContent>
      </Box>
    </Container>
  )
}

export default App;
